@font-face {
  font-family: SFCompact;
  src: url(../assets/fonts/SFCompactDisplay-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: SFCompact;
  src: url(../assets/fonts/SFCompactDisplay-Semibold.otf);
  font-weight: 600;
}

@font-face {
  font-family: SFCompact;
  src: url(../assets/fonts/SFCompactDisplay-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: SFCompact;
  src: url(../assets/fonts/SFCompactDisplay-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: SFCompact;
  src: url(../assets/fonts/SFCompactDisplay-Light.otf);
  font-weight: 300;
}
