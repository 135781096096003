@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts.css';

:root {
  --color-secondary: #977f00;
  --color-primary: #e7aa0c;
}

body {
  @apply bg-slate-50 m-0 p-0;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

table,
th,
td {
  border: none;
  border-collapse: collapse;
}
table td {
  vertical-align: baseline;
}
